/* eslint-disable no-use-before-define */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import routes from './routes'

Vue.use(VueRouter)

const globalMiddleware = []

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(
  require.context('../middleware', true, /.*\.js$/),
)

// Create the router
const router = createRouter()

async function beforeEach(to, from, next) {
  let components = []

  // Clear fields so if moving between pages prevent wrong data appear
  store.dispatch('modals/clearFields')
  store.dispatch('filters/saveCurrentRoute', to)

  try {
    // Get the matched components and resolve them.
    components = await resolveComponents(
      router.getMatchedComponents({ ...to }),
    )
  } catch (error) {
    if (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message)) {
      window.location.reload(true)
      return
    }
  }
  // Check for more middlewares
  const additionalMiddlewares = checkForMiddlewares(to)

  // Get the middleware for all the matched components.
  const middleware = getMiddleware(components, additionalMiddlewares)

  // Call each middleware.
  callMiddleware(middleware, to, from, (...args) => {
    next(...args)
  })
}

function checkForMiddlewares(to) {
  const additionalMiddlewares = ['preserve-query']

  if (to.name) {
    // This means route is 'auth.front'
    if (to.name.includes('front.')) {
      additionalMiddlewares.push('check-user', 'auth')
    } else {
      // If its not front, then admin login is required
      additionalMiddlewares.push('check-user-admin')
    }

    // This means route is 'back'
    if (to.name.includes('admin.')) {
      additionalMiddlewares.push('auth-admin', 'admin-required')
    }

    // This means route is for researcher role
    if (to.name.includes('researcher.')) {
      additionalMiddlewares.push('researcher-required')
    }

    // This means route is for evaluator role
    if (to.name.includes('evaluator.')) {
      additionalMiddlewares.push('auth-admin', 'evaluator-required')
    }
  }

  return additionalMiddlewares
}

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function afterEach() {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  store.dispatch('pagination/clearPage')
  store.dispatch('pagination/clearPerPage')

  setTimeout(() => {
    feather.replace({
      width: 14,
      height: 14,
    })
  }, 500)
}

/**
 * Create a new router instance.
 *
 * @return {Router}
 */
function createRouter() {
  const routerObject = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
    routes,
  })

  const originalPush = routerObject.push
  routerObject.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
  }

  routerObject.beforeEach(beforeEach)
  routerObject.afterEach(afterEach)

  return routerObject
}

/**
 * Resolve async components.
 *
 * @param  {Array} components
 * @return {Array}
 */
function resolveComponents(components) {
  return Promise.all(
    components.map(component => (typeof component === 'function' ? component() : component)),
  )
}

/**
 * Merge the the global middleware with the components middleware.
 *
 * @param  {Array} components
 * @return {Array}
 */
function getMiddleware(components, additionalMiddleware = null) {
  let middleware = [...globalMiddleware]

  if (additionalMiddleware) {
    middleware = middleware.concat(additionalMiddleware)
  }

  components
    .filter(c => c.middleware)
    .forEach(component => {
      if (Array.isArray(component.middleware)) {
        middleware.push(...component.middleware)
      } else {
        middleware.push(component.middleware)
      }
    })

  return middleware
}
/**
 * Call each middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function callMiddleware(middleware, to, from, next) {
  const stack = middleware.reverse()

  // eslint-disable-next-line no-underscore-dangle, consistent-return
  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      return next(...args)
    }

    // eslint-disable-next-line no-shadow
    const middleware = stack.pop()

    if (typeof middleware === 'function') {
      middleware(to, from, _next)
    } else if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next)
    } else {
      throw Error(`Undefined middleware [${middleware}]`)
    }
  }

  _next()
}

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware(requireContext) {
  return requireContext
    .keys()
    .map(file => [
      file.replace(/(^.\/)|(\.js$)/g, ''),
      requireContext(file),
    ])
    .reduce(
      (guards, [name, guard]) => ({ ...guards, [name]: guard.default }),
      {},
    )
}

export default router
