import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    education: {},
    educations: [],
    educationsTotal: 0,
  },
  getters: {
    educations: state => state.educations,
    educationsTotal: state => state.educationsTotal,
    education: state => state.education,
  },
  mutations: {
    SAVE_EDUCATIONS(state, educations) {
      state.educations = educations
    },
    SAVE_EDUCATION(state, education) {
      state.education = education
    },
    SAVE_EDUCATIONS_TOTAL(state, educationsTotal) {
      state.educationsTotal = educationsTotal
    },
    CLEAR_EDUCATIONS(state) {
      state.educations = []
    },
    CLEAR_EDUCATION(state) {
      state.education = {
        institution: [],
        published_web: true,
      }
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/educations`)
        commit('SAVE_EDUCATIONS_TOTAL', resp.data.total)
        commit('SAVE_EDUCATIONS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_EDUCATIONS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/educations/filter`, filters)
        commit('SAVE_EDUCATIONS_TOTAL', resp.data.total)
        commit('SAVE_EDUCATIONS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_EDUCATIONS')
      }
    },
    async fetchEducation({ commit }, educationId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/educations/${educationId}`)
        commit('SAVE_EDUCATION', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/educations/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files_new)
        commit('SAVE_EDUCATION', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/educations`, data)
        Vue.prototype.$saveFiles(data.files_new)
        commit('SAVE_EDUCATION', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async clear({ commit }) {
      commit('CLEAR_EDUCATION')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/educations/export`,
          filters,
          name: 'educations',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
