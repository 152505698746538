import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {
      country: null,
    },
    items: [],
    itemsTotal: 0,
    type: null,
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
    type: state => state.type,
  },
  mutations: {
    SAVE_TYPE(state, type) {
      state.type = type
    },
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
      state.itemsTotal = 0
    },
    CLEAR_ITEM(state) {
      state.item = {
        content: {
          host: null,
          recipient_institution: null,
          status: null,
          date: null,
          end_date: null,
        },
        address: 'aaa',
      }
    },
  },
  actions: {
    async fetch({ commit }, type) {
      try {
        commit('SAVE_TYPE', type)
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/activities/${type}/activity`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filter({ commit, state }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/activities/${state.type}/activity/filter`, filters)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/activities/conferences/activity/${id}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/activities/conferences/activity/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files_new)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/activities/conferences/activity`, data)
        Vue.prototype.$saveFiles(data.files_new)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async checkDates({}, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/activities/checkDates`, data)
        return resp.data
      } catch (e) {
        console.log(e)
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
    setType({ commit }, type) {
      commit('SAVE_TYPE', type)
    },
    clear({ commit }) {
      commit('SAVE_ITEM', {
        notes: [],
        multimedia: [],
        files: [],
        trips: [],
        content: {
          host: null,
          recipient_institution: null,
          other_managerial_type: null,
          dissemination_type: [],
        },
        address: null,
        country: null,
      })
    },
    export({ commit, state }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/activities/${state.type}/activity/export`,
          filters,
          name: 'researcher',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
