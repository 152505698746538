import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    lines: [],
    line: {},
    totalLines: 0,
  },
  getters: {
    lines: state => state.lines,
    line: state => state.line,
    totalLines: state => state.totalLines,
  },
  mutations: {
    SAVE_LINES(state, lines) {
      state.lines = lines
    },
    SAVE_LINE(state, line) {
      state.line = line
    },
    SAVE_TOTAL_LINES(state, total) {
      state.totalLines = total
    },
    CLEAR_LINE(state) {
      state.line = {
        publish_web: true,
        images: [],
        files: [],
      }
    },
  },
  actions: {
    async fetchLines({ commit }) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/research-lines`)
      commit('SAVE_LINES', resp.data.lines.data)
      commit('SAVE_TOTAL_LINES', resp.data.total)
    },
    async fetchLine({ commit }, lineId) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/research-lines/${lineId}`)
      commit('SAVE_LINE', resp.data.data)
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/research-lines/filter`, filters)
        if (resp.status < 300) {
          commit('SAVE_LINES', resp.data.lines.data)
          commit('SAVE_TOTAL_LINES', resp.data.total)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    export({ commit, state }) {
      try {
        Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/research-lines/export`, state.filters).then(response => {
          window.open(response.data)
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async update({ commit }, payload) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/research-lines/${payload.lineId}`, payload.data)

        Vue.prototype.$saveFiles(payload.data.files_new)
        Vue.prototype.$saveFiles(payload.data.images_new)

        if (resp.status < 300) {
          commit('SAVE_LINE', resp.data.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        console.log(data)
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/research-lines`, data)

        Vue.prototype.$saveFiles(data.files_new)
        Vue.prototype.$saveFiles(data.images_new)
        if (resp.status < 300) {
          commit('SAVE_LINE', resp.data.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanLine({ commit }) {
      commit('CLEAR_LINE')
    },
    async deleteFile({ commit }, fileId) {
      try {
        await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/files/delete/${fileId}`)
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
