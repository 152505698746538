import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    itemsTotal: 0,
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      state.item = {}
    },
  },
  actions: {
    async fetch({ commit }, data) {
      try {
        // const queryParams = this.getQueryParams(data.yearBetween)
        let queryParams = ''
        if (Object.keys(data.yearBetween).length > 0) {
          if (data.yearBetween.from && data.yearBetween.to) {
            queryParams = `?between=${data.yearBetween.from} - ${data.yearBetween.to}`
          } else if (data.yearBetween.from) {
            queryParams = `?from=${data.yearBetween.from}`
          } else if (data.yearBetween.to) {
            queryParams = `?to=${data.yearBetween.to}`
          } else if (data.yearBetween.singleYear) {
            queryParams = `?singleYear=${data.yearBetween.singleYear}`
          } else {
            queryParams = `?singleYear=${new Date().getFullYear()}`
          }
        } else {
          queryParams = `?singleYear=${new Date().getFullYear()}`
        }

        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users-scientific-stats-table${queryParams}`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async filter({ commit }, data) {
      try {
        // const queryParams = this.getQueryParams(data.yearBetween)
        let queryParams = ''
        if (data.yearBetween && Object.keys(data.yearBetween).length > 0) {
          if (data.yearBetween.from && data.yearBetween.to) {
            queryParams = `?between=${data.yearBetween.from} - ${data.yearBetween.to}`
          } else if (data.yearBetween.from) {
            queryParams = `?from=${data.yearBetween.from}`
          } else if (data.yearBetween.to) {
            queryParams = `?to=${data.yearBetween.to}`
          } else if (data.yearBetween.singleYear) {
            queryParams = `?singleYear=${data.yearBetween.singleYear}`
          } else if (data.yearBetween.calculateBetween) {
            queryParams = `?calculateBetween=${data.yearBetween.calculateBetween}`
          } else {
            queryParams = `?singleYear=${new Date().getFullYear()}`
          }
        } else {
          queryParams = `?singleYear=${new Date().getFullYear()}`
        }
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users-scientific-stats-table/filter${queryParams}`, data)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data)
      } catch (e) {
        console.log(e)
        commit('CLEAR_ITEMS')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/unpaid-leaves/${id}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/unpaid-leaves/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files_)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/unpaid-leaves`, data)
        Vue.prototype.$saveFiles(data.files_)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/unpaid-leaves/export`,
          filters,
          name: 'unpaid_leaves',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
