import store from '../store'

export default function auth(to, from, next) {
  const user = store.getters['auth/admin']

  const ignoreLoginRoutes = ['admin.login']

  if (!ignoreLoginRoutes.includes(to.name)) {
    if (user) {
      if (user.roles && ((user.roles.includes('admin') || user.roles.includes('super-admin') || user.roles.includes('researcher')))) {
        next()
      } else {
        next({ name: 'admin.login' })
      }
    } else {
      next({ name: 'admin.login' })
    }
  } else {
    next()
  }
}
