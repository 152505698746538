import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: JSON.parse(localStorage.getItem('publicationImportItems')) ?? [],
    originalItems: [],
    itemsTotal: 0,
    duplicates: 0,
    ok: 0,
    maybes: 0,
    updateds: 0,
  },
  getters: {
    items: state => state.items,
    originalItems: state => state.originalItems,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
    duplicates: state => state.duplicates,
    ok: state => state.ok,
    maybes: state => state.maybes,
    updateds: state => state.updateds,
  },
  mutations: {
    SAVE_STATUS(state, data) {
      state.duplicates = data.duplicates
      state.ok = data.ok
      state.updateds = data.updateds
      state.maybes = data.maybes
    },
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_AUTHORS(state, data) {
      state.items[state.items.findIndex(x => x.wos_id === data.wosId)].users = data.data
    },
    SAVE_SPECIFIC_ITEM(state, data) {
      const publi = data.publication
      if (publi.duplicated == 0) {
        publi.duplicated_table = '<span class="badge bg-success">No</span>'
      } else if (publi.duplicated == 1) {
        publi.duplicated_table = '<span class="badge bg-danger">Yes</span>'
      } else if (publi.duplicated == 2) {
        publi.duplicated_table = '<span class="badge bg-info">Updated</span>'
      } else if (publi.duplicated == 3) {
        publi.duplicated_table = '<span class="badge bg-warning">Maybe</span>'
      }

      state.items[data.index] = publi
      localStorage.setItem('publicationImportItems', JSON.stringify(state.items))
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      state.item = {}
    },
    SAVE_ORIGINAL_ITEMS(state, originalItems) {
      state.originalItems = originalItems
    },
  },
  actions: {
    async loadPublications({ commit }) {
      if (localStorage.getItem('publicationImportItems')) {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/publications/check-duplicates`, { items: JSON.parse(localStorage.getItem('publicationImportItems')) })
        localStorage.setItem('publicationImportItems', JSON.stringify(resp.data.items))
      }

      commit('SAVE_ITEMS', localStorage.getItem('publicationImportItems') ? JSON.parse(localStorage.getItem('publicationImportItems')) : [])
    },
    update({ commit, state }, data) {
      commit('SAVE_ITEMS', localStorage.getItem('publicationImportItems') ? JSON.parse(localStorage.getItem('publicationImportItems')) : [])
      const index = state.items.findIndex(x => x.wos_id === data.wosId)
      commit('SAVE_SPECIFIC_ITEM', { index, publication: data.publication })
    },
    async fetchId({ commit, state }, wosId) {
      if (state.items.length > 0) {
        commit('SAVE_ITEM', state.items[state.items.findIndex(x => x.wos_id === wosId)] ?? {})
      } else {
        const items = JSON.parse(localStorage.getItem('publicationImportItems'))
        commit('SAVE_ITEM', items[items.findIndex(x => x.wos_id === wosId)] ?? {})
      }
    },
    async filter({ commit, state }, filters) {
      try {
        const items = state.originalItems.filter(e => {
          if (filters.wos_id) {
            if (!e.wos_id.toLowerCase().includes(filters.wos_id.toLowerCase())) {
              return false
            }
          }

          if (filters.title) {
            if (!e.title.toLowerCase().includes(filters.title.toLowerCase())) {
              return false
            }
          }

          if (filters.year) {
            if (!e.year.toLowerCase().includes(filters.year.toLowerCase())) {
              return false
            }
          }

          if (filters.journal) {
            if (!e.journal.toLowerCase().includes(filters.journal.toLowerCase())) {
              return false
            }
          }

          return true
        })

        commit('SAVE_ITEMS', items)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async saveItems({ commit }, items) {
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/publications/check-duplicates`, { items })
      localStorage.setItem('publicationImportItems', JSON.stringify(resp.data.items))
      commit('SAVE_ITEMS', localStorage.getItem('publicationImportItems') ? JSON.parse(localStorage.getItem('publicationImportItems')) : [])
      commit('SAVE_STATUS', resp.data)
      commit('SAVE_ORIGINAL_ITEMS', resp.data.items)
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
    export({ commit, state }) {
      try {
        Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/publications/import/export`, state.items).then(response => {
          window.open(response.data)
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async applyActions({ commit, state }, data) {
      try {
        const { actions } = data
        let { items } = state

        if (actions.convertToPubli) {
          await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/publications/create-imported-publication`, { items: data.items, signed: false })
          data.items.forEach(e => {
            state.items.splice(state.items.findIndex(x => x.wos_id === e.wos_id), 1)
          })
        }

        if (actions.convertToPubliSigned && !actions.convertToPubli) {
          await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/publications/create-imported-publication`, { items: data.items, signed: true })
          data.items.forEach(e => {
            state.items.splice(state.items.findIndex(x => x.wos_id === e.wos_id), 1)
          })
        }

        if (actions.deleteItem) {
          data.items.forEach(e => {
            state.items.splice(state.items.findIndex(x => x.wos_id === e.wos_id), 1)
          })
        }

        if (actions.deleteImportedContent) {
          data.items.forEach(e => {
            state.items.splice(state.items.findIndex(x => x.wos_id === e.wos_id), 1)
          })
        }

        if (actions.deleteDuplicates) {
          items = state.items.filter(e => e.duplicated !== 1)
        }

        localStorage.setItem('publicationImportItems', JSON.stringify(items))
      } catch (e) {
        commit('FETCH_FAILED')
        console.log(e)
      }
    },
    delete({ state }, wosId) {
      const { items } = state
      items.splice(items[items.findIndex(x => x.wos_id === wosId)], 1)
      localStorage.setItem('publicationImportItems', JSON.stringify(items))
    },
    async matchAuthors({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/publications/match-authors`, { authors: data.authors })
        commit('SAVE_AUTHORS', { data: resp.data, wosId: data.wosId })
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    clearItems({ commit }) {
      localStorage.removeItem('publicationImportItems')
      commit('CLEAR_ITEMS')
    },
  },
}
