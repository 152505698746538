import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    // Positions
    position: {},
    positions: [],
    positionsTotal: 0,
    // Position list (admin)
    positionList: {},
    positionsList: [],
    positionsListTotal: 0,
  },
  getters: {
    positions: state => state.positions,
    position: state => state.position,
    positionsTotal: state => state.positionsTotal,
    // Position list (admin)
    positionsList: state => state.positionsList,
    positionList: state => state.positionList,
    positionsListTotal: state => state.positionsListTotal,
  },
  mutations: {
    SAVE_POSITION(state, position) {
      state.position = position
    },
    SAVE_POSITIONS(state, positions) {
      state.positions = positions
    },
    SAVE_POSITION_TOTAL(state, total) {
      state.positionsTotal = total
    },
    // Position list (admin)
    SAVE_POSITION_LIST(state, position) {
      state.positionList = position
    },
    SAVE_POSITIONS_LIST(state, positions) {
      state.positionsList = positions
    },
    SAVE_POSITION_LIST_TOTAL(state, total) {
      state.positionsListTotal = total
    },
    REMOVE_POSITION_LIST(state) {
      state.positionList = {}
    },
    REMOVE_POSITION(state) {
      state.position = {
        institution: [],
        department: [],
      }
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/positions`)
        commit('SAVE_POSITION_TOTAL', resp.data.total)
        commit('SAVE_POSITIONS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async fetchPosition({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/positions/${id}`)
        commit('SAVE_POSITION', resp.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/positions/filter`, filters)
        commit('SAVE_POSITION_TOTAL', resp.data.total)
        commit('SAVE_POSITIONS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    export({ commit, state }) {
      try {
        Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/positions/export`, state.filters).then(response => {
          window.open(response.data)
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async update({ commit }, payload) {
      const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/positions/${payload.positionId}`, payload.data)
      Vue.prototype.$saveFiles(payload.data.files_new)
      commit('SAVE_POSITION', resp.data.data)
    },
    async removePosition({ commit }) {
      commit('REMOVE_POSITION')
    },
    async create({ commit }, data) {
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/positions`, data)
      commit('SAVE_POSITION', resp.data.data)
    },
    // Position list (admin)
    async fetchPositionsList({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/positions-list`)
        commit('SAVE_POSITION_LIST_TOTAL', resp.data.total)
        commit('SAVE_POSITIONS_LIST', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async fetchPositionList({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/positions-list/${id}`)
        commit('SAVE_POSITION_LIST', resp.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async removePositionList({ commit }) {
      commit('REMOVE_POSITION_LIST')
    },
    async updateList({ commit }, payload) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/positions-list/${payload.id}`, payload.data)
        commit('SAVE_POSITION_LIST', resp.data.data)
      } catch (e) {
        console.log(e)
        commit('CLEAR')
      }
    },
    async createList({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/positions-list`, data)
        commit('SAVE_POSITION_LIST', resp.data.data)
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
