import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    // Host Institutions
    currentQuery: null,
    institutions: [],
    institution: {

    },
    institutionsTotal: 0,
    // Host institutions types
    types: [],
    type: {},
    typesTotal: 0,
    // Host institutions positions
    positions: [],
    position: {},
    positionsTotal: 0,
    // Host institutions contacts
    contacts: [],
    contact: {
      department: {},
    },
    contactsTotal: 0,
    // Host institutions departments
    departments: [],
    institutionsFiltered: [],
    hasRelatedData: false,
    // Departments users
    users: [],
    usersTotal: 0,
    caeHosts: [],
  },
  getters: {
    // INSTITUTIONS
    institutions: state => state.institutions,
    institution: state => state.institution,
    institutionsTotal: state => state.institutionsTotal,
    // INSTITUTIONS TYPES
    types: state => state.types,
    type: state => state.type,
    typesTotal: state => state.typesTotal,
    // INSTITUTIONS POSITIONS
    positions: state => state.positions,
    position: state => state.position,
    positionsTotal: state => state.positionsTotal,
    // INSTITUTIONS CONTACTS
    contacts: state => state.contacts,
    contact: state => state.contact,
    contactsTotal: state => state.contactsTotal,
    // INSTITUTIONS DEPARTMENTS
    departments: state => state.departments,
    // TRANSFER DATA
    institutionsFiltered: state => state.institutionsFiltered,
    hasRelatedData: state => state.hasRelatedData,
    // DEPARTMENTS USERS
    users: state => state.users,
    usersTotal: state => state.usersTotal,
    caeHosts: state => state.caeHosts,
  },
  mutations: {
    // INSTITUTIONS
    SAVE_INSTITUTION(state, institution) {
      state.institution = institution
    },
    SAVE_INSTITUTIONS(state, institutions) {
      state.institutions = institutions
    },
    SAVE_INSTITUTIONS_FILTERED(state, institutionsFiltered) {
      state.institutionsFiltered = institutionsFiltered
    },
    SAVE_RELATED_DATA(state, hasRelatedData) {
      state.hasRelatedData = hasRelatedData
    },
    SAVE_INSTITUTION_TOTAL(state, total) {
      state.institutionsTotal = total
    },
    // INSTITUTIONS
    SAVE_CONTACT(state, contact) {
      state.contact = contact
    },
    SAVE_CONTACTS(state, contacts) {
      state.contacts = contacts
    },
    SAVE_CONTACT_TOTAL(state, total) {
      state.contactsTotal = total
    },
    // INSTITUTIONS TYPES
    SAVE_TYPE(state, type) {
      state.type = type
    },
    SAVE_TYPES(state, types) {
      state.types = types
    },
    SAVE_TYPE_TOTAL(state, total) {
      state.typesTotal = total
    },
    // INSTITUTIONS POSITIONS
    SAVE_POSITION(state, position) {
      state.position = position
    },
    SAVE_POSITIONS(state, positions) {
      state.positions = positions
    },
    SAVE_POSITION_TOTAL(state, total) {
      state.positionsTotal = total
    },
    // INSTITUTIONS DEPARTMENTS
    SAVE_DEPARTMENTS(state, departments) {
      state.departments = departments
    },
    SAVE_DEPARTMENT_IN_HOST(state, departments) {
      state.institution.departments = departments
    },
    // DEPARTMENTS USERS
    SAVE_USERS(state, users) {
      state.users = users
    },
    SAVE_USERS_TOTAL(state, usersTotal) {
      state.usersTotal = usersTotal
    },
    SAVE_CAE_SEARCH(state, hosts) {
      state.caeHosts = hosts
    },
    SAVE_CURRENT_QUERY(state, currentQuery) {
      state.currentQuery = currentQuery
    },
  },
  actions: {
    // INSTITUTIONS POSITIONS //
    clearPosition({ commit }) {
      commit('SAVE_POSITION', {})
    },
    async fetchPosition({ commit }, positionId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/positions/${positionId}`)
        commit('SAVE_POSITION', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchPositions({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/positions`)
        commit('SAVE_POSITION_TOTAL', resp.data.total)
        commit('SAVE_POSITIONS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async updatePosition({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/institutions/positions/${data.id}`, data.data)
        commit('SAVE_POSITION', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async createPosition({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions/positions`, data)
        commit('SAVE_POSITION', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async deletePosition({ commit }, id) {
      try {
        await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/institutions/positions/${id}`)
      } catch (e) {
        commit('SAVE_POSITION', {})
        console.log(e)
      }
    },
    // END INSTITUTIONS POSITIONS //
    // INSTITUTIONS CONTACTS //
    clearContact({ commit }) {
      commit('SAVE_CONTACT', {})
    },
    async fetchContact({ commit }, contactId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/contacts/${contactId}`)
        commit('SAVE_CONTACT', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchContacts({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/contacts`)
        commit('SAVE_CONTACT_TOTAL', resp.data.total)
        commit('SAVE_CONTACTS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async updateContact({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/institutions/contacts/${data.id}`, data.data)
        commit('SAVE_CONTACT', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async createContact({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions/contacts`, data)
        commit('SAVE_CONTACT', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async deleteContact({ commit }, id) {
      try {
        await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/institutions/contacts/${id}`)
      } catch (e) {
        commit('SAVE_CONTACT', {})
        console.log(e)
      }
    },
    async contactsFilter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions/contacts/filter`, filters)
        commit('SAVE_CONTACT_TOTAL', resp.data.total)
        commit('SAVE_CONTACTS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async contactsExport({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/institutions/contacts/export`,
          filters,
          name: 'institutions',
        })
      } catch (e) {
        console.log(e)
        commit('CLEAR')
      }
    },
    // END INSTITUTIONS CONTACTS //
    // INSTITUTIONS TYPES //
    clearType({ commit }) {
      commit('SAVE_TYPE', {})
    },
    async fetchType({ commit }, typeId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/types/${typeId}`)
        commit('SAVE_TYPE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchTypes({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/types`)
        commit('SAVE_TYPE_TOTAL', resp.data.total)
        commit('SAVE_TYPES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async updateType({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/institutions/types/${data.id}`, data.data)
        commit('SAVE_TYPE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async createType({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions/types`, data)
        commit('SAVE_TYPE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async deleteType({ commit }, id) {
      try {
        await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/institutions/types/${id}`)
      } catch (e) {
        commit('SAVE_TYPE', {})
        console.log(e)
      }
    },
    // END INSTITUTIONS TYPES //

    // INSTITUTIONS //
    clear({ commit }) {
      commit('SAVE_INSTITUTION', {
        departments: [], notes: [], alias: [], images: [],
      })
    },
    async export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/institutions/export`,
          filters,
          name: 'institutions',
        })
      } catch (e) {
        console.log(e)
        commit('CLEAR')
      }
    },
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions`)
        commit('SAVE_INSTITUTION_TOTAL', resp.data.total)
        commit('SAVE_INSTITUTIONS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async fetchDepartments({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions/search-departments`, data)
        commit('SAVE_DEPARTMENTS', resp.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async fetchUsersByDepartment({ commit }, departmentId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/departments/${departmentId}/users`)
        commit('SAVE_USERS', resp.data.data)
        // commit('SAVE_USERS_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
      }
    },
    async transferUsers({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions/departments/${data.departmentId}/users/transfer`, data.data)
        // commit('SAVE_DEPARTMENTS', resp.data.data)
        return resp.data.status
      } catch (e) {
        commit('CLEAR')
        return 'KO'
      }
    },
    async deleteDepartment({ commit }, departmentId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/delete-departments/${departmentId}`)
        commit('SAVE_DEPARTMENT_IN_HOST', resp.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions/filter`, filters)
        commit('SAVE_INSTITUTION_TOTAL', resp.data.total)
        commit('SAVE_INSTITUTIONS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async auxFilter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions/filter`, filters)
        commit('SAVE_INSTITUTIONS_FILTERED', resp.data.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async transferData({}, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions/transfer-data/${data.id}`, { destinationId: data.destinationId })
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async checkRelations({ commit }, itemId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/check-relations/${itemId}`)
        commit('SAVE_RELATED_DATA', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchInstitution({ commit, state }, institutionId) {
      try {
        let id = institutionId
        if (!institutionId && state.institution && state.institution.id) {
          id = state.institution.id
        }

        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/${id}`)
        commit('SAVE_INSTITUTION', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/institutions/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files.filter(item => item.edited === true))
        Vue.prototype.$saveFiles(data.data.files_new)
        Vue.prototype.$saveFiles(data.data.images_new)
        Vue.prototype.$saveFiles([data.data.card_image, data.data.detail_image])
        commit('SAVE_INSTITUTION', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions`, data)
        Vue.prototype.$saveFiles(data.files_new)
        Vue.prototype.$saveFiles(data.images_new)
        Vue.prototype.$saveFiles([data.card_image, data.detail_image])
        commit('SAVE_INSTITUTION', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async deleteImage({ commit }, { institutionId, imageId }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/${institutionId}/delete-image/${imageId}`)
        commit('SAVE_INSTITUTION', resp.data.data)
        return resp.data.status
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async filterCae({ commit, state }, name) {
      try {
        if (state.currentQuery) {
          state.currentQuery.cancel('Request canceled by user')
        }

        commit('SAVE_CURRENT_QUERY', axios.CancelToken.source())

        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/institutions/filter-cae`, name, { cancelToken: state.currentQuery.token })
        commit('SAVE_CAE_SEARCH', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    // END INSTITUTIONS //
  },
}
