export default {
  namespaced: true,
  state: {
    page: localStorage.getItem('actualPage') ? localStorage.getItem('actualPage') : 1,
    perPage: localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 15,
  },
  getters: {
    page: state => state.page,
    perPage: state => state.perPage,
  },
  mutations: {
    SAVE_PAGE(state, page) {
      state.page = page
    },
    SAVE_PER_PAGE(state, perPage) {
      state.perPage = perPage
    },
  },
  actions: {
    async savePage({ commit }, page) {
      localStorage.setItem('actualPage', page)
      commit('SAVE_PAGE', page)
    },
    async clearPage({ commit }) {
      localStorage.removeItem('actualPage')
      commit('SAVE_PAGE', null)
    },
    async savePerPage({ commit }, perPage) {
      localStorage.setItem('perPage', perPage)
      commit('SAVE_PER_PAGE', perPage)
      localStorage.removeItem('actualPage')
      commit('SAVE_PAGE', null)
    },
    async clearPerPage({ commit }) {
      localStorage.removeItem('perPage')
      commit('SAVE_PER_PAGE', 15)
    },
  },
}
