import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    itemsTotal: 0,
    publications: [],
    grants: [],
    fromRouteName: 'researcher.memoir-profile.index',
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
    publications: state => state.publications,
    grants: state => state.grants,
    fromRouteName: state => state.fromRouteName,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    SAVE_PUBLICATIONS(state, publications) {
      state.publications = publications
    },
    SAVE_GRANTS(state, grants) {
      state.grants = grants
    },
    SAVE_FROM_ROUTE_NAME(state, routeName) {
      state.fromRouteName = routeName
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      state.item = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs-profiles`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async getPublications({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs-profiles/getPublications/${id}`)
        commit('SAVE_PUBLICATIONS', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getGrants({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs-profiles/get-grants/${id}`)
        commit('SAVE_GRANTS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async setPublications({}, data) {
      try {
        const aux = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoirs-profiles/setPublications/${data.id}`, data)
        return aux
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async exportCSV({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoirs-profiles/export-csv`, data, {
          responseType: 'blob',
        }).then(response => {
          console.log(response)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const a = document.createElement('a')
          a.href = url
          a.download = `MemoirsInfo_${data.year}.csv`
          a.click()
          window.URL.revokeObjectURL(url)
        })

        // Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoir-highlights/cleanup`)
      } catch (e) {
        console.log(e)
      }
    },
    async exportAwardedCSV({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoirs-profiles/export-awarded-csv`, data, {
          responseType: 'blob',
        }).then(response => {
          console.log(response)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const a = document.createElement('a')
          a.href = url
          a.download = `MemoirsInfoAwardeds_${data.year}.csv`
          a.click()
          window.URL.revokeObjectURL(url)
        })

        // Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoir-highlights/cleanup`)
      } catch (e) {
        console.log(e)
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoirs-profiles/filter`, filters)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs-profiles/${id}`)
        commit('SAVE_ITEM', resp.data.data ? resp.data.data : resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchOriginal({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs-profiles/${id}?original=true`)
        commit('SAVE_ITEM', resp.data.data ? resp.data.data : resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/memoirs-profiles/${data.id}`, data.data)
        Vue.prototype.$saveFiles([data.data.image])
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoirs-profiles`, data)
        Vue.prototype.$saveFiles([data.file])
        Vue.prototype.$saveFiles([data.image])
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async applyActions({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoirs-profiles/apply-actions`, { actions: data.actions, itemIds: data.itemIds })
      } catch (e) {
        commit('FETCH_FAILED')
        console.log(e)
      }
    },
    setRouteName({ commit }, routeName) {
      commit('SAVE_FROM_ROUTE_NAME', routeName)
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
    // export({ commit }, filters) {
    //   try {
    //     Vue.prototype.$export({
    //       url: `${Vue.prototype.$groupUrl}/memoirs/export`,
    //       filters,
    //       name: 'unpaid_leaves',
    //     })
    //   } catch (e) {
    //     console.log(e)
    //     commit('FETCH_FAILED')
    //   }
    // },
  },
}
