import useJwt from '@/auth/jwt/useJwt'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    user: {},
    researcher: {},
    admin: {},
    fakeUser: {},
    fakeUsers: localStorage.getItem('fakeUsersToken') ? JSON.parse(localStorage.getItem('fakeUsersToken')) : [],
    token: localStorage.getItem('accessToken'),
    adminToken: localStorage.getItem('accessTokenAdmin'),
    fakeUserToken: localStorage.getItem('accessTokenFakeUser'),
    aux: [],
    showableText: '',
    activeCode: false,
    sendedCode: false,
  },
  getters: {
    user: state => state.user,
    researcher: state => state.researcher,
    token: state => state.token,
    check: state => state.user.id,
    admin: state => state.admin,
    fakeUser: state => state.fakeUser,
    fakeUserToken: state => state.fakeUserToken,
    adminToken: state => state.adminToken,
    fakeUsers: state => state.fakeUsers,
    aux: state => state.aux,
    showableText: state => state.showableText,
    activeCode: state => state.activeCode,
    sendedCode: state => state.sendedCode,
  },
  mutations: {
    SAVE_USER(state, user) {
      state.user = user
    },
    SAVE_RESEARCHER(state, researcher) {
      state.researcher = researcher
    },
    SAVE_TOKEN(state, token) {
      state.token = token
    },
    SAVE_FAKE_USER(state, user) {
      state.fakeUser = user
    },
    SAVE_FAKE_USER_TOKEN(state, token) {
      state.fakeUserToken = token
    },
    SAVE_ADMIN(state, user) {
      state.admin = user
    },
    SAVE_ADMIN_TOKEN(state, token) {
      state.adminToken = token
    },
    SAVE_SHOWABLE_TEXT(state, showableText) {
      state.showableText = showableText
    },
    WRONG_USER(state) {
      state.token = null
      localStorage.removeItem('accessToken')
    },
    SAVE_FAKE_USERS(state) {
      state.fakeUsers = JSON.parse(localStorage.getItem('fakeUsersToken'))
    },
    SAVE_AUX(state, aux) {
      state.aux = aux
    },
    SAVE_ACTIVE_CODE(state, activeCode) {
      state.activeCode = activeCode
    },
    SAVE_SENDED_CODE(state, sendedCode) {
      state.sendedCode = sendedCode
    },
    REMOVE_ALL_FAKE_USERS(state) {
      state.fakeUsers = []
      localStorage.removeItem('fakeUsersToken')
    },
  },
  actions: {
    removeAllUsers({ commit }) {
      commit('REMOVE_ALL_FAKE_USERS')
    },
    async fetchResearcher({ commit }, id) {
      try {
        let resp
        if (id) {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$identityUrl}/user/${id}`)
        } else {
          resp = await Vue.prototype.$http.get(`${Vue.prototype.$identityUrl}/user/current/me`)
        }
        commit('SAVE_RESEARCHER', resp.data)
      } catch (e) {
        commit('WRONG_USER')
      }
    },
    async fetchUser({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$identityUrl}/user/current/me`)
        commit('SAVE_USER', resp.data.data)
      } catch (e) {
        commit('WRONG_USER')
      }
    },
    async fetchActiveCode({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$identityUrl}/user/check-otp/${id}`)
        commit('SAVE_ACTIVE_CODE', resp.data.status)
        commit('SAVE_SENDED_CODE', resp.data.status)
      } catch (e) {
        //
      }
    },
    async sendOtp({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$identityUrl}/user/send-otp/${data.id}`, {
          type: data.type,
        })
        commit('SAVE_SENDED_CODE', resp.data.status)
      } catch (e) {
        // return 'KO'
      }
    },
    async sendReport({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$identityUrl}/user/send-report/${data.id}`, data.data)
        return resp.data
      } catch (e) {
        // return 'KO'
        throw e
      }
    },
    async validateOtpCode({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$identityUrl}/user/validate-otp/${data.id}`, {
          otp_code: data.code,
        })
        if (resp.data.status === 'Code OK') {
          commit('SAVE_ACTIVE_CODE', 'OK')
        }
        return resp.data.status
      } catch (e) {
        console.log(e)
        return 'KO'
      }
    },
    async fetchAdmin({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$identityUrl}/user/current/me`)
        commit('SAVE_ADMIN', resp.data.data)
      } catch (e) {
        commit('WRONG_USER')
      }
    },
    async login({ commit }, payload) {
      await useJwt.login({
        email: payload.email,
        password: payload.password,
      })
        .then(res => {
          useJwt.setToken(res.data.authorisation.token)
          commit('SAVE_USER', res.data.user)
          commit('SAVE_TOKEN', res.data.authorisation.token)
        })
        .catch(() => {})
    },
    async registerApplicant({ commit }, data) {
      await useJwt.register({
        first_name: data.first_name,
        last_name_1: data.last_name_1,
        last_name_2: data.last_name_2,
        email: data.email,
        password: data.password,
        route_ok: data.route_ok,
      })
        .then(res => {
          useJwt.setToken(res.data.authorisation.token)
          commit('SAVE_USER', res.data.user)
          commit('SAVE_TOKEN', res.data.authorisation.token)
        })
        .catch(e => { throw e })
    },
    async resendEmailVerification({}, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$identityUrl}/resend-email`, {
          user_id: data.user_id,
          route_ok: data.route_ok,
        })
      } catch (e) {
        console.log(e)
      }
    },
    async forgotPassword({}, email) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$identityUrl}/reset-password`, { email })
      } catch (e) {
        throw e.response.data.response
      }
    },
    async changePassword({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$identityUrl}/change-password`, { email: data.email, password: data.password })
      } catch (e) {
        throw e.response.data.response
      }
    },
    async backLogin({ commit }, payload) {
      await useJwt.backLogin({
        email: payload.email,
        password: payload.password,
      })
        .then(async res => {
          const auxUser = res.data
          if (auxUser.user.roles_all && auxUser.user.roles_all.some(e => e.evaluator === true)) {
            localStorage.setItem('accessTokenAdmin', auxUser.authorisation.token)
            const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/modal-texts/get-text/${auxUser.user.id}?title=${payload.title}`)
            commit('SAVE_SHOWABLE_TEXT', resp.data)
            commit('SAVE_AUX', auxUser)
          } else {
            localStorage.setItem('accessTokenAdmin', res.data.authorisation.token)
            commit('SAVE_ADMIN', res.data.user)
            commit('SAVE_ADMIN_TOKEN', res.data.authorisation.token)
          }
        })
        .catch(e => {
          console.log(e)
          throw e
        })
    },
    async getShowabletext({ commit }, title) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/modal-texts/get-text?title="${title}"`)
        commit('SAVE_SHOWABLE_TEXT', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async logout({ commit }) {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      commit('WRONG_USER', null)
      commit('SAVE_USER', {})
    },
    async adminLogout({ commit, state }) {
      const params = new URLSearchParams(window.location.search)

      if (params.has('actAs')) {
        const users = state.fakeUsers

        users.splice(users.findIndex(x => x.id === parseInt(params.get('actAs'), 0)), 1)
        localStorage.setItem('fakeUsersToken', JSON.stringify(users))

        window.location.href = window.location.origin + window.location.pathname // Remove ?actAs so main user appears

        commit('SAVE_FAKE_USERS')
      } else {
        localStorage.removeItem('accessTokenAdmin')
        commit('SAVE_ADMIN', {})
        commit('SAVE_ADMIN_TOKEN', null)
        localStorage.removeItem('fakeUsersToken')
        commit('SAVE_FAKE_USERS')
      }
    },
    async fakeUserLogin({ commit, state }, userId) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$identityUrl}/user/fake-user/${userId}`)
      const user = state.fakeUsers ? state.fakeUsers.find(x => x && x.id === parseInt(userId, 0)) : null

      if (resp.data.user.roles.includes('applicant')) {
        localStorage.setItem('accessToken', resp.data.authorisation.token)
      }

      if (!user) {
        localStorage.setItem('fakeUsersToken', JSON.stringify([
          {
            id: resp.data.user.id,
            name: resp.data.user.name,
            roles: resp.data.user.roles,
            token: resp.data.authorisation.token,
          },
        ].concat(state.fakeUsers)))

        commit('SAVE_FAKE_USERS')
      }
    },
    async fakeUserLogout({ commit, state }, userId) {
      const users = state.fakeUsers

      // users.splice(users.findIndex(x => x.id === parseInt(userId, 1)))
      users.splice(users.findIndex(x => x.id === userId), 1)
      localStorage.setItem('fakeUsersToken', JSON.stringify(users))

      commit('SAVE_FAKE_USERS')
    },
    async setUser({ commit }, aux) {
      commit('SAVE_ADMIN', aux.user)
      commit('SAVE_ADMIN_TOKEN', aux.authorisation.token)
    },
  },
}
