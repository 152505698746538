import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    guests: [],
    guestsTotal: 0,
    itemsTotal: 0,
    stats: null,
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
    guests: state => state.guests,
    guestsTotal: state => state.guestsTotal,
    stats: state => state.stats,
  },
  mutations: {
    SAVE_GUESTS(state, items) {
      state.guests = items
    },
    SAVE_GUESTS_TOTAL(state, total) {
      state.guestsTotal = total
    },
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    SAVE_STATS(state, stats) {
      state.stats = stats
    },
    CLEAR_ITEM(state) {
      state.item = {
        blocks: [],
        new_blocks: [],
        audience: {
          users: [],
        },
      }
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/request-forms`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/request-forms/${id}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/request-forms/${data.id}`, data.data)

        const files = []
        resp.data.data.blocks.forEach(e => {
          if (e.file) {
            const index = data.data.new_blocks.findIndex(f => f.file_multimedia && f.file_multimedia.original_name === e.file.original_name)
            if (index !== -1) {
              files.push(data.data.new_blocks[index].file_multimedia.file)
            }

            const indexDownload = data.data.new_blocks.findIndex(f => f.file_download && f.file_download.original_name === e.file.original_name)
            if (indexDownload !== -1) {
              files.push(data.data.new_blocks[indexDownload].file_download.file)
            }
          }
        })

        if (files.length > 0) {
          const formData = new FormData()
          files.forEach((element, index) => {
            formData.append(`files-${index}`, element)
          })

          Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/files/save-file`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        }

        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/request-forms`, data)

        const files = []
        resp.data.data.blocks.forEach(e => {
          if (e.file) {
            const index = data.new_blocks.findIndex(f => f.file_multimedia && f.file_multimedia.original_name === e.file.original_name)
            if (index !== -1) {
              files.push(data.new_blocks[index].file_multimedia.file)
            }

            const indexDownload = data.new_blocks.findIndex(f => f.file_download && f.file_download.original_name === e.file.original_name)
            if (indexDownload !== -1) {
              files.push(data.new_blocks[indexDownload].file_download.file)
            }
          }
        })

        if (files.length > 0) {
          const formData = new FormData()
          files.forEach((element, index) => {
            formData.append(`files-${index}`, element)
          })

          Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/files/save-file`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        }

        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/request-forms/export`, filters).then(response => {
          window.open(response.data)
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async fetchSlug({ commit }, slug) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/request-forms/show/${slug}`)
        if (resp.status == 200) {
          commit('SAVE_ITEM', resp.data.data)
        }
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async sendReminder({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/request-forms/reminders`, data)
        if (resp.status == 200) {
          Vue.swal('Reminders sent successfully', '', 'success')
        }
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async fetchStats({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/request-forms/${id}/stats`)
        if (resp.status == 200) {
          commit('SAVE_STATS', resp.data)
        }
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async answerForm({ commit, state }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/request-forms/answer/${state.item.id}`, data)
        if (resp.status == 200) {
          commit('SAVE_ITEM', resp.data.data)
        }
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async fetchFormUsers({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/request-forms/${data.id}/guests`, data.filters)
        if (resp.status == 200) {
          commit('SAVE_GUESTS', resp.data.data)
          commit('SAVE_GUESTS_TOTAL', resp.data.total)
        }
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
