import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    itemsTotal: 0,
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      state.item = {
        notes: [],
        files_: [],
      }
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/temporary-leaves`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/temporary-leaves/filter`, filters)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/temporary-leaves/${id}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/temporary-leaves/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files_)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/temporary-leaves`, data)
        Vue.prototype.$saveFiles(data.files_)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/temporary-leaves/export`,
          filters,
          name: 'temporary_leaves',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
