import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'

Vue.component(FeatherIcon.name, FeatherIcon)

// Override the filter method globally for vue-select
vSelect.props.filter.default = (options, query, component) => options.filter(e => (e[component.label] ? e[component.label].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(query.toLowerCase()) : e.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(query.toLowerCase())))

// Install the plugin
Vue.component('v-select', vSelect)
